import React from 'react'
import { Typography, withStyles, Grid } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next"

const styles = theme => ({
  title: {
    color: theme.palette.text.primary,
    marginTop: 0,
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: 20,
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  wordsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  word: {
    fontSize: '17px',
    backgroundColor: 'red',
    borderRadius: 5,
    margin: '5px 10px',
    padding: '3px 8px',
    boxShadow: 'inset 3px 2px 8px #222'
  },
  text: {
    fontSize: '17px',
    marginBottom: '20px',
    color: theme.palette.text.primary
  },
  container: {
    textAlign: 'justify'
  }
})

const Experiences = props => {
  const [t] = useTranslation("ExperiencesPage")
  const { classes } = props

  const words = [t('serious'), t('rigorous'), t('attentive'), t('careful'), t('organized'), t('perfectionist'), t('autonomous'), t('investigative'), t('ambitious')]

  return (
    <Grid data-sal="fade" container item xs={12} className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.title}>{t('title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.text}>{t('paragraph-one')}</Typography>

        <Typography className={classes.text}>{t('paragraph-two')}</Typography>

        <Typography className={classes.text}>{t('paragraph-three')}</Typography>

        <Typography className={classes.text}>{t('paragraph-four')} </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={"h5"} className={classes.title}>{t('title-two')}</Typography>
      </Grid>

      <Grid item xs={12} className={classes.wordsContainer}>
        {_.map(words, (word, id) => {
          return (
            <Typography data-sal="slide-down" data-sal-delay="200" data-sal-easing="ease" key={id} className={classes.word}>{word}</Typography>
          )
        })}
      </Grid>
    </Grid>
  )
}

Experiences.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Experiences)
